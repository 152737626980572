import React from 'react'
import Navigation from '../components/Navigation'

function ProjectTimeline(props) {
    console.log('timeline page')
    return (
        <div>
             <Navigation page={'timeline'} auth={props.auth}/>
            <h1>This is the project page</h1>

        </div>
    )
}

export default ProjectTimeline
