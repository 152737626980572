import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
//    maxHeight: 350,
  },
  media: {
      height:250,
      objectFit: 'contain',
  },
  dialog: {
    justifyContent: 'center'
  }
});

export default function ExerciseCard(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [targetReps, setTargetReps] = React.useState(8);
  const exercise = props.KEY;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const history = useHistory();
  const handleNext = () => {
      console.log(exercise, targetReps);
      history.push(
          {
          pathname: 'workout',
          params: {
              target: targetReps,
              exercise: exercise
          }
        }
          )
      
  };

  const handleAdd = () => {
    if (targetReps<20){
    setTargetReps(targetReps +1);
    }
  };

  const handleRemove = () => {
      if (targetReps>1){
        setTargetReps(targetReps -1);
      }
    
};

  return (
    <>
      <Card className={classes.root} onClick={toggleOpen}>
        <CardActionArea>
          <CardMedia
          className={classes.media}
            component="img"
            alt={props.name}
            image={props.sauce}
            title={props.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            ></Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        justifyContent= 'center'
      >
        <DialogTitle id="alert-dialog-title">
          {"Set your repetitions target for "} {props.name.toLowerCase()}
        </DialogTitle>
        <DialogContent>
          <DialogActions className={classes.dialog}>
            <Fab color="primary" aria-label="add" onClick={handleRemove}>
              <RemoveIcon />
            </Fab>
            <Fab color="secondary">
            <h2>{targetReps}</h2>
            </Fab>

            <Fab color="primary" aria-label="add" onClick={handleAdd}>
              <AddIcon />
            </Fab>
          </DialogActions>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen} color="primary">
            CANCEL
          </Button>
          <Button onClick={handleNext} color="primary" autoFocus>
            NEXT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
