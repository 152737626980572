import React from "react";
import Navigation from "../components/Navigation";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getJustTodaysDate } from "../utilities/Helper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Card, ListSubheader } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DoughnutChart from "../components/DoughnutChart";

const columns = [
  { id: "timestamp", label: "Timestamp", minWidth: 170, align: "center" },
  { id: "exercise", label: "Exercise\u00a0", minWidth: 100, align: "center" },

  {
    id: "repetitions",
    label: "Repetitions",
    minWidth: 170,
    align: "center",
  },
  {
    id: "duration",
    label: "Duration(seconds)",
    minWidth: 170,
    align: "center",
  },
];

function createData(timestamp, exercise, repetitions, duration) {
  return { timestamp, exercise, repetitions, duration };
}

function covertTimestamp(timestamp) {
  return (
    timestamp.toDate().toDateString() +
    " " +
    timestamp.toDate().toLocaleTimeString()
  );
}

const useStyles = makeStyles({

  container: {
    maxHeight: 440,
  },
  root: {
    flexGrow: 1,
  },
  rootCard: {
    maxWidth: 500,
    minHeight: 300,
    backgroundColor: "white",
  },
});

function HistoryPage(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const { uid } = props.auth.currentUser;
  const workoutsRef = props.firestore.collection("workoutSessions");
  const workoutsQuery = workoutsRef
    .where("uid", "==", uid)
    .orderBy("serverTime");
  const [workoutsData] = useCollectionData(workoutsQuery);

  const nutritionsRef = props.firestore
    .collection("nutrition")
    .doc(uid)
    .collection("data");

  const nutritionsQuery = nutritionsRef.where(
    "timestamp",
    "==",
    new Date(selectedDate.toDateString())
  );
  const [nutritionsData] = useCollectionData(nutritionsQuery);

  let rows = [];
  if (workoutsData) {
   
    rows =  filterByDate(selectedDate, workoutsData).map((e) =>
      createData(covertTimestamp(e.localTime), e.exercise, e.reps, e.duration)
    );
  }
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Navigation page={"historypage"} auth={props.auth} />
      <h2>
        {/* Hello {props.user.displayName}, this is the page where you can see the
        history of all the past workouts.{" "} */}
      </h2>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <div style={{ padding:50 }}>
        <Grid
          container
          spacing={2}
          justify="space-evenly"
          alignItems="center"
          padding="50"
        >
          <Grid item xl={4}>
            {nutritionsData &&
              makeCard(
                "Breakfast",
                extractItems("breakfast", nutritionsData),
                classes
              )}
          </Grid>
          <Grid item xl={4}>
            {nutritionsData &&
              makeCard("Lunch", extractItems("lunch", nutritionsData), classes)}
          </Grid>
          <Grid item xl={4}>
            {nutritionsData &&
              makeCard(
                "Dinner",
                extractItems("dinner", nutritionsData),
                classes
              )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default HistoryPage;

const makeCard = (title, data, classes) => {
  const itemList = data[0];
  const calories = data[1];
  if (calories > 0) {
    return (
      <Card variant="outlined" className={classes.rootCard}>
        <div className={classes.rootCard}>
          <ListSubheader component="div" id="nested-list-subheader">
            <h1>
              {title} ({calories} calories)
            </h1>
          </ListSubheader>
          <Divider />
          <List component="nav">
            {itemList.map((e) => (
              <ListItem>
                <ListItemText primary={e} />
              </ListItem>
            ))}
          </List>
        </div>
      </Card>
    );
  }

  return (
    <Card variant="outlined" className={classes.rootCard}>
      <div className={classes.rootCard}>
        <ListSubheader component="div" id="nested-list-subheader">
          <h1>
            {title} ({calories} calories)
          </h1>
        </ListSubheader>
        <Divider />
        <List component="nav">
          <ListItem>
            <ListItemText primary="*No items added*" />
          </ListItem>
        </List>
      </div>
    </Card>
  );
};

function extractItems(name, arr) {
  let items = [];
  let calories = 0;
  arr.map((e) => {
    if (e.mealType === name) {
      items.push(e.item);
      calories += e.calories;
    }
    return 0;
  });
  return [items, calories];
}

function filterByDate(date, arr) {
  let rows = [];
  arr.map((e) => {
 
      if (e.localDate &&
        new Date(e.localDate.toDate()).toDateString() ===
        date.toDateString()
      ) {
        rows.push(e);
      }
    
  });
  return rows.reverse();
}
