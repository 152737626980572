import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles({
  root: {
    width: "50%",
  },
});

export default function LinearBuffer(props) {
  const classes = useStyles();
  const target = props.target;
  const progress = (props.progress / target) *100;
  const buffer = (props.buffer / target) * 100;

  return (
    <div className={classes.root}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </div>
  );
}
