import { useUserMediaVideo } from "use-user-media"
import { useEffect, useState } from 'react'

function Camera() {
  const width =600;
  const height = 500;
  let input = undefined;
  const [image, setImage] = useState()
  const userMediaDisabled = typeof input === "object";

  const [userMediaError, video] = useUserMediaVideo({audio: false, video: true})
  useEffect(() => {

    if (userMediaDisabled) {
      input.width = width
      input.height = height
      setImage(input)
      return
    }
    if (userMediaError) {
      setImage(userMediaError)
      return
    }
    if (video) {
      video.width = width
      video.height = height
      setImage(video)
    }
  }, [userMediaDisabled, userMediaError, height, input, video, width])
  return image
}
export default Camera



