import React from 'react'
import Navigation from '../components/Navigation'

function Home(props) {
    console.log(props)
    console.log('home')
    return (
        <div>
            <Navigation page={'home'} auth={props.auth}/>
            This is the Home Page
        </div>
    )
}

export default Home
