import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getJustTodaysDate } from "../utilities/Helper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getFoodData } from "../utilities/Data";
import Navigation from "../components/Navigation";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Card, ListSubheader } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DoughnutChart from "../components/DoughnutChart";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rootCard: {
    maxWidth: 500,
    minHeight: 300,
    backgroundColor: "white",
  },
}));

function NutritionPage(props) {
  const { uid } = props.auth.currentUser;
  const date = getJustTodaysDate();
  const dbRef = props.firestore
    .collection("nutrition")
    .doc(uid)
    .collection("data");

  const query = dbRef.where("timestamp", "==", date);
  const [dbData] = useCollectionData(query);

  async function addToFirestore(mealType, item, qty, calories) {
    await dbRef.add({
      mealType: mealType,
      item: item,
      servings: qty,
      calories: calories,
      timestamp: date,
    });
  }

  const classes = useStyles();
  const [mealType, setMealType] = React.useState("breakfast");
  const [foodItem, setFoodItem] = React.useState();
  const [qty, setQty] = React.useState(1);
  const [serving, setServing] = React.useState();
  const [cal, setCal] = React.useState(0);

  const handleChangeMealType = (event) => {
    setMealType(event.target.value);
    console.log("mealtype changed: " + event.target.value);
  };

  const handleChangeFoodItem = (itemData) => {
    setFoodItem(itemData);
    if (itemData) {
      setServing(itemData.Serving);
      setCal(qty * itemData.Calories);
    } else {
      setServing();
      setCal(0);
    }
  };
  const handleQtyChange = (event) => {
    setQty(event.target.value);
    setCal(event.target.value * foodItem.Calories);
  };

  const handleSubmit = () => {
    addToFirestore(mealType, foodItem.Food, qty, cal);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-evenly" alignItems="center">
        <Navigation page={"nutritionpage"} auth={props.auth} />
        <div style={{ padding: 50 }}>
          <Grid
            container
            spacing={5}
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xl={3}>
              <FormControl className={classes.formControl}>
                <Select
                  value={mealType}
                  onChange={handleChangeMealType}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "Meal" }}
                >
                  <MenuItem value={"breakfast"}>
                    <em>Breakfast</em>
                  </MenuItem>
                  <MenuItem value={"lunch"}>Lunch</MenuItem>
                  <MenuItem value={"dinner"}>Dinner</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={3}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(e, v) => handleChangeFoodItem(v)}
                options={foodData}
                getOptionLabel={(option) => option.Food}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="What did you eat?"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {foodItem ? (
              <>
                <Grid item xl={3}>
                  <div>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={qty}
                        onChange={handleQtyChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Qty" }}
                      >
                        <MenuItem value={1}>
                          <em>{"1 x " + serving}</em>
                        </MenuItem>
                        <MenuItem value={2}>{"2 x " + serving}</MenuItem>
                        <MenuItem value={3}>{"3 x " + serving}</MenuItem>
                        <MenuItem value={4}>{"4 x " + serving}</MenuItem>
                        <MenuItem value={5}>{"5 x " + serving}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xl={3}></Grid>
                <Chip color="secondary" label={cal + " Calories"} />
              </>
            ) : (
              <Grid item xl={3}></Grid>
            )}
            <Grid item xl={3}>
              {foodItem ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Add to today's consumption
                </Button>
              ) : (
                <Button variant="contained" disabled>
                  Add to today's consumption
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={2}
          justify="space-evenly"
          alignItems="center"
          padding="50"
        >
          <DoughnutChart data={getCombinedCalories(dbData)[1]}/>
          <h1>
            <i>Total calories consumed today: {getCombinedCalories(dbData)[0]}</i>
          </h1>
        </Grid>
        <div style={{ padding: 0 }}>
          <Grid
            container
            spacing={2}
            justify="space-evenly"
            alignItems="center"
            padding="50"
          >
            <Grid item xl={4}>
              {dbData &&
                makeCard(
                  "Breakfast",
                  extractItems("breakfast", dbData),
                  classes
                )}
            </Grid>
            <Grid item xl={4}>
              {dbData &&
                makeCard("Lunch", extractItems("lunch", dbData), classes)}
            </Grid>
            <Grid item xl={4}>
              {dbData &&
                makeCard("Dinner", extractItems("dinner", dbData), classes)}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default NutritionPage;

const foodData = getFoodData();

const makeCard = (title, data, classes) => {
  const itemList = data[0];
  const calories = data[1];
  if (calories > 0) {
    return (
      <Card variant="outlined" className={classes.rootCard}>
        <div className={classes.rootCard}>
          <ListSubheader component="div" id="nested-list-subheader">
            <h1>
              {title} ({calories} calories)
            </h1>
          </ListSubheader>
          <Divider />
          <List component="nav">
            {itemList.map((e) => (
              <ListItem>
                <ListItemText primary={e} />
              </ListItem>
            ))}
          </List>
        </div>
      </Card>
    );
  }

  return (
    <Card variant="outlined" className={classes.rootCard}>
      <div className={classes.rootCard}>
        <ListSubheader component="div" id="nested-list-subheader">
          <h1>
            {title} ({calories} calories)
          </h1>
        </ListSubheader>
        <Divider />
        <List component="nav">
          <ListItem>
            <ListItemText primary="*No items added*" />
          </ListItem>
        </List>
      </div>
    </Card>
  );
};

function extractItems(name, arr) {
  let items = [];
  let calories = 0;
  arr.map((e) => {
    if (e.mealType === name) {
      items.push(e.item);
      calories += e.calories;
    }
    return 0;
  });
  return [items, calories];
}

function getCombinedCalories(dbData) {
  if (dbData) {
    let breakfastCal = extractItems("breakfast", dbData)[1];
    let lunchCal = extractItems("lunch", dbData)[1];
    let dinnerCal = extractItems("dinner", dbData)[1];

    let totalCal = breakfastCal + lunchCal + dinnerCal;
    let arr = [breakfastCal, lunchCal, dinnerCal];
    console.log([totalCal, arr])
    return [totalCal, arr];
  }
  return [0, [0, 0, 0]];
}
