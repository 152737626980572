const drawKP = (ctx, image, pose, tmPose, showKP) => {
  if (image) {
    ctx.drawImage(image, 0, 0, 600, 500);

    if (pose && showKP === true) {
      const minPartConfidence = 0.5;
      tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ctx);
      tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ctx);
    }
  }
};

export default drawKP;

const planWorkout = (target) => {
  var dict = { reps: 0, target: target, completed: false };

  return dict;
};

export { planWorkout };

const exerciseComplete = (dict) => {
  if (dict["completed"] === false) {
    return false;
  }
  return true;
};

export { exerciseComplete };

const incrementRepInSet = (dict) => {
  if (dict["completed"] === false) {
    dict["reps"] += 1;

    if (dict["reps"] === dict["target"]) {
      dict["completed"] = true;
    }

    return dict;
  }
  dict["reps"] = "All sets completed";
  return dict;
};

export { incrementRepInSet };

const getCurrentSet = (dict) => {
  if (dict["completed"] === false) {
    return dict;
  }
};

export { getCurrentSet };

const getTimestamp = () => {
  return Math.round(new Date().getTime() / 1000);
};
export { getTimestamp };

const makeRepChartDataSet = (dict, value, time) => {
  dict.values.push(value);
  dict.timestamps.push(time);
  return dict;
};
export { makeRepChartDataSet };

const tmURL = "https://teachablemachine.withgoogle.com/models/";
const model = "./Models/";
const files = "/model_files/";

export const exercisesData = {
  // combined: {
  //   name: "Combined Exercises",
  //   oflline: model + "combined" + files,
  //   online: tmURL + "fRR_oD3JR/",
  //   sauce: '',

  // },

  shoulders: {
    name: "Shoulder Press",
    offline: model + "shoulders" + files,
    online: tmURL + "UA95VcAvq/",
    sauce: 'https://media.giphy.com/media/iGLJ0SlNsW2X1e6a7n/giphy.gif',
    message: ['Lift the weights above your head','Bring the weights down (90 degrees from your elbow)']
  },
  biceps: {
    name: "Bicep Curls",
    offline: model + "biceps" + files,
    online: tmURL + "sFtaA_e71/",
    sauce: 'https://media.giphy.com/media/UuHejiG98CaUJWIdTj/giphy.gif',
    message: ['Lift the weights above by bending your elbows', 'Bring the weights down']
    
  },
  squats: {
    name: "Squats",
    offline: model + "squats" + files,
    online: tmURL + "fRbOQZQXn/",
    sauce: 'https://media.giphy.com/media/lrgqgKM8uFmQ21j0dN/giphy.gif',
    message: ['Go down', 'Go up']
  },
  pushups: {
    name: "Push ups",
    offline: model + "pushups" + files,
    online: tmURL + "jqIvTVEgv/",
    sauce: 'https://media.giphy.com/media/RKNAWSvKBONiF7iAtL/giphy.gif',
    message: ['Go down', 'Go up']
  },
};

const getModelURL = (exerciseKey) => {
  return exercisesData[exerciseKey]["offline"];
};
export { getModelURL };


const getRepInterval = (time) => {
  let timeArray = []
  let repArray = []

  for (let i =0; i< Math.max(...time) +1; i++){
    if (!time.includes(i)){
      repArray.push(0)
    } else{
      repArray.push(1)
    }
    timeArray.push(i)
  }

  return [repArray, timeArray]

};
export { getRepInterval };

const getExerciseName = (key) =>{
  return exercisesData[key]['name'];
}
export {getExerciseName};

const getExerciseMessage = (key) =>{
  return exercisesData[key]['message'];
}
export {getExerciseMessage};

const getJustTodaysDate = () =>{
  return new Date(new Date().toDateString())
}
export {getJustTodaysDate};