import React from "react";
import ExerciseCard from "../components/ExerciseCard";
import Navigation from "../components/Navigation";
import { exercisesData } from "../utilities/Helper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    padding: 20
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    
  },
}));

function SelectExercise(props) {
  const exercises = exercisesData;
  const items = [];

  const classes = useStyles();

  for (var key in exercises) {
    items.push(
      <Grid key={key} item xl={4}>
         
        <ExerciseCard
          name={exercises[key].name}
          sauce={exercises[key].sauce}
          key={key}
          KEY={key}
        />
      </Grid>
    );
  }

  return (
    <>
      <Navigation page={"select"} auth={props.auth}/>
      {/* <h2>Welcome {props.user.displayName}, select any one of the exercises:</h2> */}
      <div className={classes.root}>
        <Grid container spacing={3} justify="space-evenly" alignItems="center">
          {items}
        </Grid>
      </div>
    </>
  );
}

export default SelectExercise;
