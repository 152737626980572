import React from "react";
import { Line } from "react-chartjs-2";

function makeData(x, y, labelText) {
  const labels = y;
  const dataSet = x;

  const data = {
    labels: labels,
    datasets: [
      {
        label: labelText,
        data: dataSet,
        fill: false,
        borderColor: "orange",
      },
    ],
  };
  return data;
}

export default function RepChart(props) {
  return (
    
    <div style={{ height: 200, width: 600 }}>
      <Line
        data={makeData(props.db, props.labels, props.labeltext)}
        options={{ maintainAspectRatio: false, responsive: true }}
      />
    </div>
  );
}
