import "./App.css";
import Tracker from "./components/Tracker";
import Home from "./pages/Home";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProjectTimeline from "./pages/ProjectTimeline";
import SelectExercise from "./pages/SelectExercise";
import ExerciseCompleted from "./pages/ExerciseCompleted";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useAuthState } from "react-firebase-hooks/auth";
import HistoryPage from "./pages/HistoryPage";
import NutritionPage from "./pages/NutritionPage";
import { makeStyles } from "@material-ui/core/styles";


firebase.initializeApp({
  apiKey: "AIzaSyAHUg_2IFMN5FpNDcQU4TnIJkC2_kQ86fc",
  authDomain: "pro-fitness-app-dissertation.firebaseapp.com",
  projectId: "pro-fitness-app-dissertation",
  storageBucket: "pro-fitness-app-dissertation.appspot.com",
  messagingSenderId: "569207376869",
  appId: "1:569207376869:web:c59537e0140c4ac556bd0c",
  measurementId: "G-4K79LK5X18",
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function App() {
  const [user] = useAuthState(auth);
  return (
    <>
      <BrowserRouter>
        {!user ? (
          <SignIn />
        ) : (
          <Switch>
            <Redirect exact from="/" to="select" />
            {/* <Route exact path="/home">
              <Home auth={auth} user={user} firestore={firestore}/>
            </Route> */}
            <Route exact path="/workout">
              <Tracker
                data={this}
                auth={auth}
                user={user}
                firestore={firestore}
              />
            </Route>
            <Route exact path="/timeline">
              <ProjectTimeline auth={auth} user={user} firestore={firestore} />
            </Route>
            <Route exact path="/select">
              <SelectExercise auth={auth} user={user} firestore={firestore} />
            </Route>
            <Route exact path="/exercise-completed">
              <ExerciseCompleted
                auth={auth}
                user={user}
                firestore={firestore}
              />
            </Route>
            <Route exact path="/historypage">
              <HistoryPage auth={auth} user={user} firestore={firestore} />
            </Route>
            <Route exact path="/nutritionpage">
              <NutritionPage auth={auth} user={user} firestore={firestore} />
            </Route>
          </Switch>
        )}
      </BrowserRouter>
    </>
  );
}


function SignIn() {
  const classes = useStyles();
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="space-evenly" alignItems="center">
        <Grid item xl>
        <Button
          variant="contained"
          color="primary"
          onClick={signInWithGoogle}
          style={{
            position: 'absolute', 
            left: '50%', 
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
        >
          Sign in with Google
        </Button>
        </Grid>

      </Grid>
    </div>
  );
}

export default App;
