import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Navigation from "../components/Navigation";
import RepChart from "../components/RepChart";
import { getRepInterval } from "../utilities/Helper";




function ExerciseCompleted(props) {

    const data = useLocation().params;
    
    const history = useHistory()

    if (!data) {
        history.push('select')
        window.location.reload();
    }
    const repIntervals = getRepInterval(data.repInt)

  return (
    <>
      <Navigation page={"select"} auth={props.auth}/>

      <h1>Repetitions completed: {data.target}</h1>
      <h1>Total elapsed time: {data.totalTime} seconds. </h1>

      <RepChart labels={repIntervals[1]} db={repIntervals[0]} labeltext={'Repetitions vs Time'} />


    </>
  );
}

export default ExerciseCompleted;
