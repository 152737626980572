import { cleanup } from "@testing-library/react";
import React, { useState, useRef, useEffect } from "react";
import LoadClassifier from "../model/Classifier.js";
import Camera from "./Camera";
import drawKP, {
  planWorkout,
  incrementRepInSet,
  getTimestamp,
  getExerciseName,
  getJustTodaysDate,
  getExerciseMessage,
} from "../utilities/Helper";
import { canvasStyle, width, height } from "../utilities/Props";
import "./Tracker.css";
import RepChart from "./RepChart";
import Navigation from "./Navigation.js";

import { useHistory, useLocation } from "react-router-dom";
import LinearBuffer from "./LinearBuffer.js";
import Button from "@material-ui/core/Button";
import { FpsView } from "react-fps";
import Grid from "@material-ui/core/Grid";
import firebase from "firebase/app";
import "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function Tracker(props) {
  const classes = useStyles();
  const input = useLocation().params;
  const history = useHistory();

  if (!input) {
    history.push("select");
    window.location.reload();
  }

  const [ctx, setCtx] = useState();
  const net = LoadClassifier(input.exercise);
  const image = Camera();
  const tmPose = window.tmPose;

  const frameRate = undefined;

  const [showKP, setShowKP] = useState(true);

  let flag = useRef(false);
  let minValue = useRef(0);
  let maxValue = useRef(0);
  let start = useRef(false);
  const [message, setMessage] = useState("Loading classifier...");

  const exercisePlan = useRef(planWorkout(input.target));

  const [reps, setReps] = useState(0);
  const target = exercisePlan.current.target;

  const startTime = useRef(getTimestamp());

  const [labels, setLabels] = useState([0]);
  const [db, setDb] = useState([0]);
  const repInt = useRef([]);

  useEffect(() => {
    if (!net || !image || !ctx)
      return () => {
        "Loading classifier...";
      };
    if ([net, image].some((elem) => elem instanceof Error)) return () => {};

    let intervalId;
    let requestId;
    let graphInterval;
    let workoutComplete = false;
    function cleanUp() {
      clearInterval(intervalId);
      clearInterval(graphInterval);
      cancelAnimationFrame(requestId);
    }

    async function estimate() {
      try {
        const { pose, posenetOutput } = await net.estimatePose(image);
        const prediction = await net.predict(posenetOutput);

        minValue.current = parseFloat(prediction[0].probability.toFixed(2));
        maxValue.current = parseFloat(prediction[1].probability.toFixed(2));

        if (start.current === false) {
          setMessage("Start performing the workout");
        }

        if (minValue.current === 1.0 && flag.current === false) {
          start.current = true;
        }

        if (
          maxValue.current === 1.0 &&
          flag.current === false &&
          start.current === true
        ) {
          setMessage(getExerciseMessage(input.exercise)[1]);
          exercisePlan.current = incrementRepInSet(exercisePlan.current);
          setReps(exercisePlan.current.reps);
          repInt.current.push(getTimestamp() - startTime.current);

          flag.current = true;
        }
        if (minValue.current === 1.0 && flag.current === true) {
          setMessage(getExerciseMessage(input.exercise)[0]);
          flag.current = false;
        }
        if ((reps === target) & !workoutComplete) {
          addToFirestore();

          history.push({
            pathname: "exercise-completed",
            params: {
              target: target,
              totalTime: getTimestamp() - startTime.current,
              repInt: repInt.current,
            },
          });
          workoutComplete = true;
        }

        drawKP(ctx, image, pose, tmPose, showKP);
      } catch (err) {
        cleanup();
      }
    }

    if (frameRate) {
      intervalId = setInterval(estimate, Math.round(1000 / frameRate));
      return cleanUp();
    }

    function animate() {
      estimate();
      requestId = requestAnimationFrame(animate);
    }

    graphInterval = setInterval(() => {
      let value = maxValue.current;
      let time = getTimestamp() - startTime.current;
      setDb([...db, value]);
      setLabels([...labels, time]);
    }, 100);

    requestId = requestAnimationFrame(animate);

    async function addToFirestore() {
      const { uid } = props.auth.currentUser;
      const dbRef = props.firestore.collection("workoutSessions");
      await dbRef.add({
        uid,
        reps: target,
        exercise: getExerciseName(input.exercise),
        localTime: new Date(),
        localDate: getJustTodaysDate(),
        serverTime: firebase.firestore.FieldValue.serverTimestamp(),
        duration: getTimestamp() - startTime.current,
      });
    }

    return cleanUp;
  }, [
    input.key,
    ctx,
    net,
    image,
    tmPose,
    showKP,
    frameRate,
    reps,
    exercisePlan,
    db,
    labels,
    history,
    target,
    input.exercise,
    props.auth.currentUser,
    props.firestore,
    props.user.displayName,
  ]);

  function handleKeypointToggle() {
    const prev = showKP;
    setShowKP(!prev);
  }

  return (
    <div className={classes.root} justify="center" align="center">
      <Navigation page={"select"} auth={props.auth} />
      <Grid container>
        <Grid item xl={6}>
          <canvas
            id="tracker__canvas"
            style={canvasStyle}
            ref={(c) => {
              if (c) {
                setCtx(c.getContext("2d"));
              }
            }}
            height={height}
            width={width}
          />
        </Grid>
        <Grid item xl={6}>
          <Chip label={message} color="secondary" />
          <RepChart
            labels={labels}
            db={db}
            labeltext={"Maximum stress on the targeted muscle"}
          />
          <h1>
            <i>
              Repetitions: {reps}/{target}
            </i>
          </h1>
          {showKP ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleKeypointToggle}
            >
              Hide Keypoints
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleKeypointToggle}
            >
              Show Keypoints
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container>
        {/* <FpsView /> */}

        <LinearBuffer
          progress={reps}
          buffer={reps + maxValue.current}
          target={target}
        />
      </Grid>
    </div>
  );
}

export default Tracker;
