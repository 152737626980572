let foodData = [
    {
        "Food": "Artichoke",
        "Serving": "1 artichoke (128 g)",
        "Calories": 60
    },
    {
        "Food": "Arugula",
        "Serving": "1 leaf (2 g)",
        "Calories": 1
    },
    {
        "Food": "Asparagus",
        "Serving": "1 spear (12 g)",
        "Calories": 2
    },
    {
        "Food": "Aubergine",
        "Serving": "1 aubergine (458 g)",
        "Calories": 115
    },
    {
        "Food": "Beetroot",
        "Serving": "1 beet (82 g)",
        "Calories": 35
    },
    {
        "Food": "Bell Pepper",
        "Serving": "1 pepper (73 g)",
        "Calories": 15
    },
    {
        "Food": "Black Olives",
        "Serving": "1 olive (2.7 g)",
        "Calories": 2
    },
    {
        "Food": "Broccoli",
        "Serving": "1 bunch (608 g)",
        "Calories": 207
    },
    {
        "Food": "Brussels Sprouts",
        "Serving": "1 sprout (19 g)",
        "Calories": 8
    },
    {
        "Food": "Cabbage",
        "Serving": "1 head (908 g)",
        "Calories": 227
    },
    {
        "Food": "Capsicum",
        "Serving": "1 pepper (45 g)",
        "Calories": 12
    },
    {
        "Food": "Carrot",
        "Serving": "1 carrot (61 g)",
        "Calories": 25
    },
    {
        "Food": "Cauliflower",
        "Serving": "1 floweret (13 g)",
        "Calories": 3
    },
    {
        "Food": "Celery",
        "Serving": "1 stalk (40 g)",
        "Calories": 6
    },
    {
        "Food": "Chard",
        "Serving": "1 leaf (48 g)",
        "Calories": 9
    },
    {
        "Food": "Cherry Tomato",
        "Serving": "1 cherry tomato (20 g)",
        "Calories": 20
    },
    {
        "Food": "Chicory",
        "Serving": "1 head (53 g)",
        "Calories": 38
    },
    {
        "Food": "Chinese Cabbage",
        "Serving": "1 head (840 g)",
        "Calories": 134
    },
    {
        "Food": "Chives",
        "Serving": "1 tbsp, chopped (3 g)",
        "Calories": 1
    },
    {
        "Food": "Collard Greens",
        "Serving": "1 cup, raw (36 g)",
        "Calories": 12
    },
    {
        "Food": "Corn",
        "Serving": "1 cup (154 g)",
        "Calories": 562
    },
    {
        "Food": "Courgette",
        "Serving": "1 courgette (196 g)",
        "Calories": 33
    },
    {
        "Food": "Creamed Spinach",
        "Serving": "1 cup (200 g)",
        "Calories": 148
    },
    {
        "Food": "Cucumber",
        "Serving": "1 cucumber (410 g)",
        "Calories": 66
    },
    {
        "Food": "Eggplant",
        "Serving": "1 eggplant (458 g)",
        "Calories": 115
    },
    {
        "Food": "Endive",
        "Serving": "1 head (513 g)",
        "Calories": 87
    },
    {
        "Food": "Fennel",
        "Serving": "1 bulb (234 g)",
        "Calories": 73
    },
    {
        "Food": "Garlic",
        "Serving": "1 clove (3 g)",
        "Calories": 4
    },
    {
        "Food": "Gherkin",
        "Serving": "1 gherkin (65 g)",
        "Calories": 9
    },
    {
        "Food": "Gourd",
        "Serving": "1 gourd (771 g)",
        "Calories": 108
    },
    {
        "Food": "Green Beans",
        "Serving": "1 cup (110 g)",
        "Calories": 34
    },
    {
        "Food": "Green Olives",
        "Serving": "1 olive (2.7 g)",
        "Calories": 2
    },
    {
        "Food": "Green Onion",
        "Serving": "1 green onion (15 g)",
        "Calories": 5
    },
    {
        "Food": "Horseradish",
        "Serving": "1 tbsp (15 g)",
        "Calories": 7
    },
    {
        "Food": "Kale",
        "Serving": "1 cup, chopped (67 g)",
        "Calories": 33
    },
    {
        "Food": "Kohlrabi",
        "Serving": "1 kohlrabi (400 g)",
        "Calories": 108
    },
    {
        "Food": "Kumara",
        "Serving": "1 kumara (130 g)",
        "Calories": 112
    },
    {
        "Food": "Leek",
        "Serving": "1 leek (89 g)",
        "Calories": 54
    },
    {
        "Food": "Lettuce",
        "Serving": "1 head (600 g)",
        "Calories": 90
    },
    {
        "Food": "Mushrooms",
        "Serving": "1 mushroom (5.4 g)",
        "Calories": 1
    },
    {
        "Food": "Mustard Greens",
        "Serving": "1 cup, chopped (56 g)",
        "Calories": 15
    },
    {
        "Food": "Nori",
        "Serving": "1 sheet (2.6 g)",
        "Calories": 1
    },
    {
        "Food": "Okra",
        "Serving": "1 pod (12 g)",
        "Calories": 4
    },
    {
        "Food": "Olives",
        "Serving": "1 olive (2.7 g)",
        "Calories": 2
    },
    {
        "Food": "Onion",
        "Serving": "1 onion (85 g)",
        "Calories": 34
    },
    {
        "Food": "Parsnips",
        "Serving": "1 parsnip (170 g)",
        "Calories": 128
    },
    {
        "Food": "Peas",
        "Serving": "1 cup (98 g)",
        "Calories": 79
    },
    {
        "Food": "Pepper",
        "Serving": "1 pepper (75 g)",
        "Calories": 20
    },
    {
        "Food": "Potato",
        "Serving": "1 potato (213 g)",
        "Calories": 164
    },
    {
        "Food": "Pumpkin",
        "Serving": "1 pumpkin (196 g)",
        "Calories": 51
    },
    {
        "Food": "Radishes",
        "Serving": "1 radish (4.5 g)",
        "Calories": 1
    },
    {
        "Food": "Red Cabbage",
        "Serving": "1 leaf (22 g)",
        "Calories": 7
    },
    {
        "Food": "Rutabaga",
        "Serving": "1 rutabaga (386 g)",
        "Calories": 147
    },
    {
        "Food": "Shallots",
        "Serving": "1 shallot (25 g)",
        "Calories": 18
    },
    {
        "Food": "Spinach",
        "Serving": "1 bunch (340 g)",
        "Calories": 78
    },
    {
        "Food": "Squash",
        "Serving": "1 squash (196 g)",
        "Calories": 88
    },
    {
        "Food": "Sweet Potato",
        "Serving": "1 potato (130 g)",
        "Calories": 112
    },
    {
        "Food": "Tomato",
        "Serving": "1 tomato (111 g)",
        "Calories": 20
    },
    {
        "Food": "Turnip Greens",
        "Serving": "1 turnip green (170 g)",
        "Calories": 34
    },
    {
        "Food": "Turnips",
        "Serving": "1 turnip (122 g)",
        "Calories": 34
    },
    {
        "Food": "Wasabi",
        "Serving": "1 root (169 g)",
        "Calories": 184
    },
    {
        "Food": "Winter Squash",
        "Serving": "1 squash (431 g)",
        "Calories": 147
    },
    {
        "Food": "Zucchini",
        "Serving": "1 zucchini (196 g)",
        "Calories": 33
    },
    {
        "Food": "Acai",
        "Serving": "1 oz. (28.35 g)",
        "Calories": 20
    },
    {
        "Food": "Apple",
        "Serving": "1 apple (182 g)",
        "Calories": 95
    },
    {
        "Food": "Applesauce",
        "Serving": "1 cup (246 g)",
        "Calories": 167
    },
    {
        "Food": "Apricot",
        "Serving": "1 apricot (35 g)",
        "Calories": 17
    },
    {
        "Food": "Avocado",
        "Serving": "1 avocado (200 g)",
        "Calories": 320
    },
    {
        "Food": "Banana",
        "Serving": "1 banana (125 g)",
        "Calories": 111
    },
    {
        "Food": "Blackberries",
        "Serving": "1 cup (144 g)",
        "Calories": 62
    },
    {
        "Food": "Blood Oranges",
        "Serving": "1 serving (140 g)",
        "Calories": 70
    },
    {
        "Food": "Blueberries",
        "Serving": "1 cup (148 g)",
        "Calories": 84
    },
    {
        "Food": "Cantaloupe",
        "Serving": "1 wedge (69 g)",
        "Calories": 23
    },
    {
        "Food": "Cherries",
        "Serving": "1 cherry (8 g)",
        "Calories": 4
    },
    {
        "Food": "Clementine",
        "Serving": "1 clementine (74 g)",
        "Calories": 35
    },
    {
        "Food": "Cranberries",
        "Serving": "1 cup (100 g)",
        "Calories": 46
    },
    {
        "Food": "Currants",
        "Serving": "1 cup (112 g)",
        "Calories": 63
    },
    {
        "Food": "Custard Apple",
        "Serving": "1 custard apple (135 g)",
        "Calories": 136
    },
    {
        "Food": "Dates",
        "Serving": "1 date (7.1 g)",
        "Calories": 20
    },
    {
        "Food": "Figs",
        "Serving": "1 fig (50 g)",
        "Calories": 37
    },
    {
        "Food": "Fruit salad",
        "Serving": "1 cup (249 g)",
        "Calories": 125
    },
    {
        "Food": "Grapes",
        "Serving": "1 cup (151 g)",
        "Calories": 104
    },
    {
        "Food": "Greengage",
        "Serving": "1 fruit (5 g)",
        "Calories": 2
    },
    {
        "Food": "Guava",
        "Serving": "1 guava (55 g)",
        "Calories": 37
    },
    {
        "Food": "Jackfruit",
        "Serving": "1 cup (151 g)",
        "Calories": 143
    },
    {
        "Food": "Jujube",
        "Serving": "1 oz. (28.35 g)",
        "Calories": 22
    },
    {
        "Food": "Kiwi",
        "Serving": "1 liwi (183 g)",
        "Calories": 112
    },
    {
        "Food": "Lemon",
        "Serving": "1 lemon (58 g)",
        "Calories": 17
    },
    {
        "Food": "Lime",
        "Serving": "1 lime (67 g)",
        "Calories": 20
    },
    {
        "Food": "Lychees",
        "Serving": "1 lychee (10 g)",
        "Calories": 7
    },
    {
        "Food": "Mandarin Oranges",
        "Serving": "1 mandarin orange (88 g)",
        "Calories": 47
    },
    {
        "Food": "Mango",
        "Serving": "1 mango (336 g)",
        "Calories": 202
    },
    {
        "Food": "Minneola",
        "Serving": "1 minneola (109 g)",
        "Calories": 70
    },
    {
        "Food": "Mulberries",
        "Serving": "1 cup (140 g)",
        "Calories": 60
    },
    {
        "Food": "Nectarine",
        "Serving": "1 nectarine (150 g)",
        "Calories": 66
    },
    {
        "Food": "Olives",
        "Serving": "1 olive (2.7 g)",
        "Calories": 2
    },
    {
        "Food": "Orange",
        "Serving": "1 orange (131 g)",
        "Calories": 62
    },
    {
        "Food": "Papaya",
        "Serving": "1 fruit (500 g)",
        "Calories": 215
    },
    {
        "Food": "Passion Fruit",
        "Serving": "1 passoin fruit (18 g)",
        "Calories": 17
    },
    {
        "Food": "Peach",
        "Serving": "1 peach (150 g)",
        "Calories": 59
    },
    {
        "Food": "Pear",
        "Serving": "1 pear (178 g)",
        "Calories": 101
    },
    {
        "Food": "Persimmon",
        "Serving": "1 fruit (25 g)",
        "Calories": 32
    },
    {
        "Food": "Physalis",
        "Serving": "1 berry (5 g)",
        "Calories": 2
    },
    {
        "Food": "Pineapple",
        "Serving": "1 pineapple (905 g)",
        "Calories": 453
    },
    {
        "Food": "Plantains",
        "Serving": "1 plantain (179 g)",
        "Calories": 218
    },
    {
        "Food": "Plum",
        "Serving": "1 plum (66 g)",
        "Calories": 30
    },
    {
        "Food": "Pomegranate",
        "Serving": "1 pomegranate (282 g)",
        "Calories": 234
    },
    {
        "Food": "Quince",
        "Serving": "1 quince (92 g)",
        "Calories": 52
    },
    {
        "Food": "Raisins",
        "Serving": "1 cup (145 g)",
        "Calories": 434
    },
    {
        "Food": "Rambutan",
        "Serving": "1 rambutan (9 g)",
        "Calories": 7
    },
    {
        "Food": "Raspberries",
        "Serving": "1 cup (123 g)",
        "Calories": 64
    },
    {
        "Food": "Rhubarb",
        "Serving": "1 stalk (51 g)",
        "Calories": 11
    },
    {
        "Food": "Starfruit",
        "Serving": "1 star fruit (91 g)",
        "Calories": 28
    },
    {
        "Food": "Strawberries",
        "Serving": "1 cup (152 g)",
        "Calories": 49
    },
    {
        "Food": "Tamarind",
        "Serving": "1 tamarind (2 g)",
        "Calories": 5
    },
    {
        "Food": "Tangerine",
        "Serving": "1 tangerine (88 g)",
        "Calories": 47
    },
    {
        "Food": "Watermelon",
        "Serving": "1 wedge (286 g)",
        "Calories": 86
    },
    {
        "Food": "Baby Back Ribs",
        "Serving": "1 serving (139 g)",
        "Calories": 360
    },
    {
        "Food": "Bacon and Eggs",
        "Serving": "1 serving (214 g)",
        "Calories": 539
    },
    {
        "Food": "Baked Beans",
        "Serving": "1 cup (260 g)",
        "Calories": 244
    },
    {
        "Food": "BBQ Ribs",
        "Serving": "1 rib (141 g)",
        "Calories": 360
    },
    {
        "Food": "Beef Stew",
        "Serving": "1 cup (196 g)",
        "Calories": 186
    },
    {
        "Food": "Biryani",
        "Serving": "1 cup (275 g)",
        "Calories": 484
    },
    {
        "Food": "Black Pudding",
        "Serving": "1 pudding (40 g)",
        "Calories": 101
    },
    {
        "Food": "Black Rice",
        "Serving": "1/2 cup (100 g)",
        "Calories": 323
    },
    {
        "Food": "BLT",
        "Serving": "1 item (240 g)",
        "Calories": 593
    },
    {
        "Food": "Brown Rice",
        "Serving": "1 cup (185 g)",
        "Calories": 670
    },
    {
        "Food": "Burrito",
        "Serving": "1 burrito (200 g)",
        "Calories": 326
    },
    {
        "Food": "Butter Chicken",
        "Serving": "1 tray (350 g)",
        "Calories": 490
    },
    {
        "Food": "California Roll",
        "Serving": "1 roll (35 g)",
        "Calories": 33
    },
    {
        "Food": "Chicken Caesar Salad",
        "Serving": "1 salad (309 g)",
        "Calories": 392
    },
    {
        "Food": "Chicken Fried Steak",
        "Serving": "1 steak (280 g)",
        "Calories": 423
    },
    {
        "Food": "Chicken Marsala",
        "Serving": "1 serving (2301 g)",
        "Calories": 2209
    },
    {
        "Food": "Chicken Parmesan",
        "Serving": "1 piece (227 g)",
        "Calories": 250
    },
    {
        "Food": "Chicken Pot Pie",
        "Serving": "1 pie (302 g)",
        "Calories": 673
    },
    {
        "Food": "Chicken Tikka Masala",
        "Serving": "1 serving (241 g)",
        "Calories": 195
    },
    {
        "Food": "Chili con Carne",
        "Serving": "1 cup (253 g)",
        "Calories": 266
    },
    {
        "Food": "Chimichanga",
        "Serving": "1 item (180 g)",
        "Calories": 418
    },
    {
        "Food": "Cobb Salad",
        "Serving": "1 serving (536 g)",
        "Calories": 632
    },
    {
        "Food": "Collard Greens",
        "Serving": "1 cup (45 g)",
        "Calories": 13
    },
    {
        "Food": "Corn Dog",
        "Serving": "1 item (175 g)",
        "Calories": 438
    },
    {
        "Food": "Corned Beef Hash",
        "Serving": "1 can (213 g)",
        "Calories": 349
    },
    {
        "Food": "Cottage Pie",
        "Serving": "1 pie (376 g)",
        "Calories": 523
    },
    {
        "Food": "Dal",
        "Serving": "1/2 cup (92 g)",
        "Calories": 304
    },
    {
        "Food": "Deviled Eggs",
        "Serving": "1/2 egg (31 g)",
        "Calories": 62
    },
    {
        "Food": "Dim Sum",
        "Serving": "1 piece (19 g)",
        "Calories": 37
    },
    {
        "Food": "Dosa",
        "Serving": "1 serving (435 g)",
        "Calories": 287
    },
    {
        "Food": "Enchiladas",
        "Serving": "1 enchilada (192 g)",
        "Calories": 323
    },
    {
        "Food": "Fajita",
        "Serving": "1 serving (248 g)",
        "Calories": 290
    },
    {
        "Food": "Fish and Chips",
        "Serving": "1 serving (300 g)",
        "Calories": 585
    },
    {
        "Food": "Fried Rice",
        "Serving": "1 serving (356 g)",
        "Calories": 662
    },
    {
        "Food": "Fried Shrimp",
        "Serving": "1 shrimp (27 g)",
        "Calories": 75
    },
    {
        "Food": "Grilled Cheese Sandwich",
        "Serving": "1 sandwich (136 g)",
        "Calories": 392
    },
    {
        "Food": "Ham and Cheese Sandwich",
        "Serving": "1 sandwich (146 g)",
        "Calories": 352
    },
    {
        "Food": "Hummus",
        "Serving": "1 cup (246 g)",
        "Calories": 435
    },
    {
        "Food": "Jambalaya",
        "Serving": "1 cup (250 g)",
        "Calories": 250
    },
    {
        "Food": "Kebab",
        "Serving": "1 kebab (360 g)",
        "Calories": 774
    },
    {
        "Food": "Lasagne",
        "Serving": "1 serving (215 g)",
        "Calories": 284
    },
    {
        "Food": "Mac and Cheese",
        "Serving": "1 serving (189 g)",
        "Calories": 699
    },
    {
        "Food": "Macaroni and Cheese",
        "Serving": "1 serving (189 g)",
        "Calories": 699
    },
    {
        "Food": "Mashed Potatoes",
        "Serving": "1 cup (210 g)",
        "Calories": 174
    },
    {
        "Food": "Meat Pie",
        "Serving": "1 pie (120 g)",
        "Calories": 290
    },
    {
        "Food": "Meatloaf",
        "Serving": "1 loaf (284 g)",
        "Calories": 721
    },
    {
        "Food": "Naan",
        "Serving": "1 bread (84 g)",
        "Calories": 260
    },
    {
        "Food": "Orange Chicken",
        "Serving": "1 serving (162 g)",
        "Calories": 420
    },
    {
        "Food": "Pad Thai",
        "Serving": "1 cup (250 g)",
        "Calories": 375
    },
    {
        "Food": "Paella",
        "Serving": "1/2 package (128 g)",
        "Calories": 200
    },
    {
        "Food": "Paratha",
        "Serving": "1 paratha (80 g)",
        "Calories": 260
    },
    {
        "Food": "Pea Soup",
        "Serving": "1 cup (253 g)",
        "Calories": 190
    },
    {
        "Food": "Peanut Butter Sandwich",
        "Serving": "1 sandwich (49 g)",
        "Calories": 200
    },
    {
        "Food": "Peking Duck",
        "Serving": "1/2 duck (178 g)",
        "Calories": 401
    },
    {
        "Food": "Philly Cheese Steak",
        "Serving": "1 sandwich (120 g)",
        "Calories": 300
    },
    {
        "Food": "Pizza",
        "Serving": "1 slice (102 g)",
        "Calories": 272
    },
    {
        "Food": "Pork Chop",
        "Serving": "1 chop (131 g)",
        "Calories": 295
    },
    {
        "Food": "Potato Salad",
        "Serving": "1/3 cup (95 g)",
        "Calories": 136
    },
    {
        "Food": "Pulled Pork Sandwich",
        "Serving": "1 sandwich (261 g)",
        "Calories": 551
    },
    {
        "Food": "Ramen",
        "Serving": "1 package, dry (85 g)",
        "Calories": 380
    },
    {
        "Food": "Ravioli",
        "Serving": "5 ravioli (66 g)",
        "Calories": 134
    },
    {
        "Food": "Reuben Sandwich",
        "Serving": "1 sandwich (308 g)",
        "Calories": 641
    },
    {
        "Food": "Roast Beef",
        "Serving": "1 slice (21 g)",
        "Calories": 23
    },
    {
        "Food": "Roast Dinner",
        "Serving": "1 slice (121 g)",
        "Calories": 240
    },
    {
        "Food": "Samosa",
        "Serving": "1 samosa (50 g)",
        "Calories": 107
    },
    {
        "Food": "Sausage Roll",
        "Serving": "1 roll (103 g)",
        "Calories": 361
    },
    {
        "Food": "Sausage Rolls",
        "Serving": "1 roll (34 g)",
        "Calories": 101
    },
    {
        "Food": "Shepherds Pie",
        "Serving": "1 pie (227 g)",
        "Calories": 159
    },
    {
        "Food": "Shrimp Cocktail",
        "Serving": "1 oz. (28 g)",
        "Calories": 130
    },
    {
        "Food": "Sloppy Joe",
        "Serving": "1/2 cup (142 g)",
        "Calories": 101
    },
    {
        "Food": "Sloppy Joes",
        "Serving": "1 bun (258 g)",
        "Calories": 397
    },
    {
        "Food": "Spaghetti Bolognese",
        "Serving": "1 serving (283 g)",
        "Calories": 374
    },
    {
        "Food": "Spring Roll",
        "Serving": "1 roll (140 g)",
        "Calories": 350
    },
    {
        "Food": "Spring Rolls",
        "Serving": "1 roll (140 g)",
        "Calories": 350
    },
    {
        "Food": "Taco",
        "Serving": "1 taco (98 g)",
        "Calories": 213
    },
    {
        "Food": "Tandoori Chicken",
        "Serving": "1 serving (175 g)",
        "Calories": 198
    },
    {
        "Food": "Yorkshire Pudding",
        "Serving": "1 pudding (15 g)",
        "Calories": 83
    },
    {
        "Food": "Amaranth",
        "Serving": "1 cup (193 g)",
        "Calories": 716
    },
    {
        "Food": "Barley",
        "Serving": "1 cup (157 g)",
        "Calories": 556
    },
    {
        "Food": "Barley Groats",
        "Serving": "1 cup (31 g)",
        "Calories": 31
    },
    {
        "Food": "Brown Rice",
        "Serving": "1 cup (195 g)",
        "Calories": 757
    },
    {
        "Food": "Buckwheat",
        "Serving": "1 cup (170 g)",
        "Calories": 583
    },
    {
        "Food": "Buckwheat Groats",
        "Serving": "1 cup (164 g)",
        "Calories": 567
    },
    {
        "Food": "Corn Waffles",
        "Serving": "1 waffle (40 g)",
        "Calories": 110
    },
    {
        "Food": "Cornmeal",
        "Serving": "1 cup (122 g)",
        "Calories": 442
    },
    {
        "Food": "Cornstarch",
        "Serving": "1 cup (128 g)",
        "Calories": 488
    },
    {
        "Food": "Couscous",
        "Serving": "1 cup (173 g)",
        "Calories": 650
    },
    {
        "Food": "Cracker",
        "Serving": "1 cracker (7 g)",
        "Calories": 35
    },
    {
        "Food": "Durum Wheat Semolina",
        "Serving": "1 tbsp (30 g)",
        "Calories": 119
    },
    {
        "Food": "Flaxseed",
        "Serving": "1 cup (168 g)",
        "Calories": 897
    },
    {
        "Food": "Freekeh",
        "Serving": "1 cup (160 g)",
        "Calories": 832
    },
    {
        "Food": "Gluten",
        "Serving": "1 oz. (28 g)",
        "Calories": 104
    },
    {
        "Food": "Grissini",
        "Serving": "1 grissini (5 g)",
        "Calories": 20
    },
    {
        "Food": "Kamut",
        "Serving": "1 cup (186 g)",
        "Calories": 627
    },
    {
        "Food": "Millet",
        "Serving": "1 cup (128 g)",
        "Calories": 484
    },
    {
        "Food": "Millet Flour",
        "Serving": "1 cup (140 g)",
        "Calories": 521
    },
    {
        "Food": "Millet Gruel",
        "Serving": "1 cup (174 g)",
        "Calories": 80
    },
    {
        "Food": "Oat Bran",
        "Serving": "1 cup (94 g)",
        "Calories": 231
    },
    {
        "Food": "Pearl Barley",
        "Serving": "1 cup (157 g)",
        "Calories": 553
    },
    {
        "Food": "Polenta",
        "Serving": "1 cup (150 g)",
        "Calories": 549
    },
    {
        "Food": "Prawn Crackers",
        "Serving": "1 cracker (3 g)",
        "Calories": 16
    },
    {
        "Food": "Pretzel Sticks",
        "Serving": "20 sticks (12 g)",
        "Calories": 46
    },
    {
        "Food": "Quinoa",
        "Serving": "1 cup (170 g)",
        "Calories": 626
    },
    {
        "Food": "Rusk",
        "Serving": "1 rusk (10 g)",
        "Calories": 41
    },
    {
        "Food": "Rye Bran",
        "Serving": "1 cup (150 g)",
        "Calories": 422
    },
    {
        "Food": "Sago",
        "Serving": "1 oz. (28 g)",
        "Calories": 99
    },
    {
        "Food": "Savoury Biscuits",
        "Serving": "40 grams (40 g)",
        "Calories": 139
    },
    {
        "Food": "Shortbread",
        "Serving": "1 cookie (19 g)",
        "Calories": 95
    },
    {
        "Food": "Spelt",
        "Serving": "1 cup (174 g)",
        "Calories": 588
    },
    {
        "Food": "Spelt Bran",
        "Serving": "1 cup (160 g)",
        "Calories": 283
    },
    {
        "Food": "Spelt Semolina",
        "Serving": "1 cup (167 g)",
        "Calories": 601
    },
    {
        "Food": "Sunflower Seeds",
        "Serving": "1 cup (140 g)",
        "Calories": 818
    },
    {
        "Food": "Tortilla",
        "Serving": "1 tortilla (197 g)",
        "Calories": 467
    },
    {
        "Food": "Tortilla Chips",
        "Serving": "20 chips (32 g)",
        "Calories": 160
    },
    {
        "Food": "Wheat Bran",
        "Serving": "1 cup (58 g)",
        "Calories": 125
    },
    {
        "Food": "Wheat Germ",
        "Serving": "1 cup (113 g)",
        "Calories": 432
    },
    {
        "Food": "Wheat Gluten",
        "Serving": "1 tsp (3 g)",
        "Calories": 10
    },
    {
        "Food": "Wheat Semolina",
        "Serving": "1 cup (167 g)",
        "Calories": 601
    },
    {
        "Food": "Wheat Starch",
        "Serving": "1 cup (110 g)",
        "Calories": 386
    },
    {
        "Food": "Whole Grain Wheat",
        "Serving": "1 cup (120 g)",
        "Calories": 407
    },
    {
        "Food": "Wholegrain Oat",
        "Serving": "1 cup (80 g)",
        "Calories": 300
    },
    {
        "Food": "Almond Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Apricot Kernel Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Argan Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 125
    },
    {
        "Food": "Avocado Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 120
    },
    {
        "Food": "Babassu Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Canola Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Coconut Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 120
    },
    {
        "Food": "Corn Oil",
        "Serving": "1 tbsp (15 ml)",
        "Calories": 120
    },
    {
        "Food": "Cottonseed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Flaxseed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Grape Seed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Hazelnut Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Linseed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 117
    },
    {
        "Food": "Menhaden Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 128
    },
    {
        "Food": "Mustard Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Oat Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Olive Oil",
        "Serving": "1 tbsp (15 ml)",
        "Calories": 120
    },
    {
        "Food": "Palm Kernel Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Palm Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Peanut Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 120
    },
    {
        "Food": "Poppy Seed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Pumpkin Seed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Rice Bran Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Safflower Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 120
    },
    {
        "Food": "Salmon Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 128
    },
    {
        "Food": "Sesame Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Shea Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Soy Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 123
    },
    {
        "Food": "Sunflower Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Tomato Seed Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Vegetable Oil",
        "Serving": "1 tbsp (15 ml)",
        "Calories": 120
    },
    {
        "Food": "Walnut Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 124
    },
    {
        "Food": "Wheat Germ Oil",
        "Serving": "1 tbsp (14 ml)",
        "Calories": 130
    },
    {
        "Food": "Alphabet Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 62
    },
    {
        "Food": "Bean Stew",
        "Serving": "1 cup (252 g)",
        "Calories": 335
    },
    {
        "Food": "Beef Bouillon",
        "Serving": "1 cup (240 g)",
        "Calories": 7
    },
    {
        "Food": "Beef Noodle Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 83
    },
    {
        "Food": "Beef Soup",
        "Serving": "1 cup (213 g)",
        "Calories": 70
    },
    {
        "Food": "Bouillon",
        "Serving": "1 cup (244 g)",
        "Calories": 39
    },
    {
        "Food": "Broccoli Cheese Soup",
        "Serving": "1 can (303 g)",
        "Calories": 264
    },
    {
        "Food": "Broccoli Soup",
        "Serving": "1 cup (237 g)",
        "Calories": 206
    },
    {
        "Food": "Cabbage Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 69
    },
    {
        "Food": "Carrot Ginger Soup",
        "Serving": "1 serving (381 g)",
        "Calories": 95
    },
    {
        "Food": "Carrot Soup",
        "Serving": "1 serving (381 g)",
        "Calories": 95
    },
    {
        "Food": "Chicken Bouillon",
        "Serving": "1 cup (241 g)",
        "Calories": 10
    },
    {
        "Food": "Chicken Broth",
        "Serving": "1 cup (244 g)",
        "Calories": 39
    },
    {
        "Food": "Chicken Gumbo Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 56
    },
    {
        "Food": "Chicken Noodle Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 62
    },
    {
        "Food": "Chicken Stock",
        "Serving": "1 cup (244 g)",
        "Calories": 39
    },
    {
        "Food": "Chicken Vegetable Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 77
    },
    {
        "Food": "Chicken with Rice Soup",
        "Serving": "1 cup (243 g)",
        "Calories": 58
    },
    {
        "Food": "Cream of Asparagus Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 85
    },
    {
        "Food": "Cream of Broccoli Soup",
        "Serving": "1 serving (310 g)",
        "Calories": 140
    },
    {
        "Food": "Cream of Celery Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 92
    },
    {
        "Food": "Cream of Chicken Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 117
    },
    {
        "Food": "Cream of Mushroom Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 97
    },
    {
        "Food": "Cream of Onion Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 107
    },
    {
        "Food": "Cream of Potato Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 73
    },
    {
        "Food": "Creamy Chicken Noodle Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 56
    },
    {
        "Food": "French Onion Soup",
        "Serving": "1 cup (243 g)",
        "Calories": 56
    },
    {
        "Food": "Golden Mushroom Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 161
    },
    {
        "Food": "Goulash",
        "Serving": "1 serving (187 g)",
        "Calories": 1887
    },
    {
        "Food": "Instant Ramen",
        "Serving": "1 package (85 g)",
        "Calories": 371
    },
    {
        "Food": "Lentil Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 139
    },
    {
        "Food": "Lobster Bisque Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 248
    },
    {
        "Food": "Meatball Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 120
    },
    {
        "Food": "Minestrone",
        "Serving": "1 cup (241 g)",
        "Calories": 82
    },
    {
        "Food": "Mushroom Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 85
    },
    {
        "Food": "Noodle Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 83
    },
    {
        "Food": "Onion Soup",
        "Serving": "1 cup (243 g)",
        "Calories": 56
    },
    {
        "Food": "Oxtail Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 68
    },
    {
        "Food": "Pea Soup",
        "Serving": "1 cup (253 g)",
        "Calories": 190
    },
    {
        "Food": "Potato Soup",
        "Serving": "1 serving (240 g)",
        "Calories": 192
    },
    {
        "Food": "Pumpkin Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 71
    },
    {
        "Food": "Ramen",
        "Serving": "1 package (85 g)",
        "Calories": 371
    },
    {
        "Food": "Scotch Broth",
        "Serving": "1 cup (241 g)",
        "Calories": 80
    },
    {
        "Food": "Succotash",
        "Serving": "1 cup (192 g)",
        "Calories": 221
    },
    {
        "Food": "Thai Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 147
    },
    {
        "Food": "Tomato Rice Soup",
        "Serving": "1 cup (247 g)",
        "Calories": 116
    },
    {
        "Food": "Tomato Soup",
        "Serving": "1 cup (248 g)",
        "Calories": 74
    },
    {
        "Food": "Vegetable Beef Soup",
        "Serving": "1 cup (244 g)",
        "Calories": 76
    },
    {
        "Food": "Vegetable Broth",
        "Serving": "1 cup (235 g)",
        "Calories": 12
    },
    {
        "Food": "Vegetable Soup",
        "Serving": "1 cup (241 g)",
        "Calories": 67
    },
    {
        "Food": "Vegetable Stock",
        "Serving": "1 cup (235 g)",
        "Calories": 12
    },
    {
        "Food": "Wedding Soup",
        "Serving": "1 cup (245 g)",
        "Calories": 130
    },
    {
        "Food": "Baskin-Robbins",
        "Serving": "1 scoop (71 g)",
        "Calories": 170
    },
    {
        "Food": "Ben and Jerry’s",
        "Serving": "1 scoop (92 g)",
        "Calories": 210
    },
    {
        "Food": "Butter Pecan Ice Cream",
        "Serving": "1 scoop (113 g)",
        "Calories": 280
    },
    {
        "Food": "Carvel",
        "Serving": "1 cup (212 g)",
        "Calories": 449
    },
    {
        "Food": "Chocolate Chip Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 155
    },
    {
        "Food": "Chocolate Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 156
    },
    {
        "Food": "Ciao Bella",
        "Serving": "1 cup (220 g)",
        "Calories": 240
    },
    {
        "Food": "Coffee Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 170
    },
    {
        "Food": "Cold Stone Creamery",
        "Serving": "1 scoop (142 g)",
        "Calories": 329
    },
    {
        "Food": "Cookie Dough Ice Cream",
        "Serving": "1/2 cup (65 g)",
        "Calories": 130
    },
    {
        "Food": "Crunchie McFlurry",
        "Serving": "1 mcflurry (183 g)",
        "Calories": 318
    },
    {
        "Food": "Dairy Milk McFlurry",
        "Serving": "1 mcflurry (183 g)",
        "Calories": 340
    },
    {
        "Food": "Dippin Dots",
        "Serving": "1 cup (170 g)",
        "Calories": 381
    },
    {
        "Food": "Double Rainbow",
        "Serving": "1 scoop (72 g)",
        "Calories": 185
    },
    {
        "Food": "Drumsticks",
        "Serving": "1 cone (141 g)",
        "Calories": 360
    },
    {
        "Food": "French Vanilla Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 145
    },
    {
        "Food": "Friendly’s",
        "Serving": "1 scoop (66 g)",
        "Calories": 140
    },
    {
        "Food": "Healthy Choice",
        "Serving": "1 bar (64 g)",
        "Calories": 80
    },
    {
        "Food": "Hot Fudge Sundae",
        "Serving": "1 sundae (179 g)",
        "Calories": 333
    },
    {
        "Food": "Ice Cream Sandwich",
        "Serving": "1 bar (112 g)",
        "Calories": 265
    },
    {
        "Food": "Ice Cream Sundae",
        "Serving": "1 sundae (178 g)",
        "Calories": 253
    },
    {
        "Food": "Ice Milk",
        "Serving": "1 scoop (103 g)",
        "Calories": 164
    },
    {
        "Food": "Magnolia",
        "Serving": "1 scoop (72 g)",
        "Calories": 166
    },
    {
        "Food": "Magnum",
        "Serving": "1 magnum (86 g)",
        "Calories": 258
    },
    {
        "Food": "Magnum Almond",
        "Serving": "1 magnum (86 g)",
        "Calories": 271
    },
    {
        "Food": "Magnum Double Caramel",
        "Serving": "1 magnum (86 g)",
        "Calories": 305
    },
    {
        "Food": "Magnum Double Chocolate",
        "Serving": "1 magnum (86 g)",
        "Calories": 327
    },
    {
        "Food": "Magnum Gold",
        "Serving": "1 magnum (85 g)",
        "Calories": 290
    },
    {
        "Food": "Magnum White",
        "Serving": "1 magnum (86 g)",
        "Calories": 255
    },
    {
        "Food": "McFlurry",
        "Serving": "1 mcflurry (134 g)",
        "Calories": 205
    },
    {
        "Food": "McFlurry Oreo",
        "Serving": "1 mcflurry (183 g)",
        "Calories": 340
    },
    {
        "Food": "Mini Milk",
        "Serving": "1 mini milk (25 g)",
        "Calories": 30
    },
    {
        "Food": "Mint Chocolate Chip Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 172
    },
    {
        "Food": "Rocky Road Ice Cream",
        "Serving": "1 scoop (113 g)",
        "Calories": 290
    },
    {
        "Food": "Schwan’s",
        "Serving": "1 scoop (72 g)",
        "Calories": 177
    },
    {
        "Food": "Smarties McFlurry",
        "Serving": "1 mcflurry (202 g)",
        "Calories": 400
    },
    {
        "Food": "Snickers Ice Cream",
        "Serving": "1 bar (50 g)",
        "Calories": 180
    },
    {
        "Food": "Soft Serve",
        "Serving": "1 cup (86 g)",
        "Calories": 191
    },
    {
        "Food": "Solero",
        "Serving": "1 solero (75 g)",
        "Calories": 75
    },
    {
        "Food": "Strawberry Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 170
    },
    {
        "Food": "Strawberry Sundae",
        "Serving": "1 sundae (178 g)",
        "Calories": 281
    },
    {
        "Food": "Sundae",
        "Serving": "1 scoop (72 g)",
        "Calories": 155
    },
    {
        "Food": "Turkey Hill",
        "Serving": "1 sandwich (70 g)",
        "Calories": 190
    },
    {
        "Food": "Vanilla Cone",
        "Serving": "1 cone (142 g)",
        "Calories": 230
    },
    {
        "Food": "Vanilla Ice Cream",
        "Serving": "1 scoop (72 g)",
        "Calories": 145
    },
    {
        "Food": "Cannelloni",
        "Serving": "2 oz. (56 g)",
        "Calories": 82
    },
    {
        "Food": "Capellini",
        "Serving": "2 oz. (56 g)",
        "Calories": 198
    },
    {
        "Food": "Cappelletti",
        "Serving": "2 oz. (56 g)",
        "Calories": 92
    },
    {
        "Food": "Cellophane Noodles",
        "Serving": "1 cup (140 g)",
        "Calories": 491
    },
    {
        "Food": "Cheese Tortellini",
        "Serving": "1 cup (113 g)",
        "Calories": 329
    },
    {
        "Food": "Dampfnudel",
        "Serving": "1 dampfnudel (85 g)",
        "Calories": 233
    },
    {
        "Food": "Dumpling Dough",
        "Serving": "2 oz. (56 g)",
        "Calories": 55
    },
    {
        "Food": "Durum Wheat Semolina",
        "Serving": "2 oz. (56 g)",
        "Calories": 222
    },
    {
        "Food": "Egg Noodles",
        "Serving": "1 cup (38 g)",
        "Calories": 146
    },
    {
        "Food": "Farfalle",
        "Serving": "2 oz. (56 g)",
        "Calories": 200
    },
    {
        "Food": "Fettuccine",
        "Serving": "2 oz. (56 g)",
        "Calories": 198
    },
    {
        "Food": "Fusilli",
        "Serving": "2 oz. (56 g)",
        "Calories": 197
    },
    {
        "Food": "Glass Noodles",
        "Serving": "2 oz. (56 g)",
        "Calories": 108
    },
    {
        "Food": "Lasagne Sheets",
        "Serving": "1 sheet (21 g)",
        "Calories": 57
    },
    {
        "Food": "Linguine",
        "Serving": "2 oz. (56 g)",
        "Calories": 200
    },
    {
        "Food": "Low Carb Pasta",
        "Serving": "2 oz. (56 g)",
        "Calories": 158
    },
    {
        "Food": "Macaroni",
        "Serving": "1 cup (114 g)",
        "Calories": 422
    },
    {
        "Food": "Manicotti",
        "Serving": "2 oz. (56 g)",
        "Calories": 200
    },
    {
        "Food": "Mostaccioli",
        "Serving": "2 oz. (56 g)",
        "Calories": 103
    },
    {
        "Food": "Orecchiette",
        "Serving": "2 oz. (56 g)",
        "Calories": 207
    },
    {
        "Food": "Orzo",
        "Serving": "2 oz. (56 g)",
        "Calories": 200
    },
    {
        "Food": "Penne",
        "Serving": "2 oz. (56 g)",
        "Calories": 197
    },
    {
        "Food": "Penne Rigate",
        "Serving": "2 oz. (56 g)",
        "Calories": 207
    },
    {
        "Food": "Pierogi",
        "Serving": "3 pierogi (100 g)",
        "Calories": 200
    },
    {
        "Food": "Ravioli",
        "Serving": "2 oz. (56 g)",
        "Calories": 43
    },
    {
        "Food": "Rigatoni",
        "Serving": "2 oz. (56 g)",
        "Calories": 198
    },
    {
        "Food": "Rotini",
        "Serving": "2 oz. (56 g)",
        "Calories": 198
    },
    {
        "Food": "Shells",
        "Serving": "1 cup (85 g)",
        "Calories": 300
    },
    {
        "Food": "Shirataki Noodles",
        "Serving": "2 oz. (56 g)",
        "Calories": 10
    },
    {
        "Food": "Soy Noodles",
        "Serving": "2 oz. (56 g)",
        "Calories": 121
    },
    {
        "Food": "Spaetzle",
        "Serving": "2 oz. (56 g)",
        "Calories": 206
    },
    {
        "Food": "Spaghetti",
        "Serving": "2 oz. (56 g)",
        "Calories": 207
    },
    {
        "Food": "Spinach Tortellini",
        "Serving": "1 cup (102 g)",
        "Calories": 320
    },
    {
        "Food": "Spirelli",
        "Serving": "2 oz. (56 g)",
        "Calories": 206
    },
    {
        "Food": "Tagliatelle",
        "Serving": "2 oz. (56 g)",
        "Calories": 207
    },
    {
        "Food": "Tortellini",
        "Serving": "1 cup (113 g)",
        "Calories": 329
    },
    {
        "Food": "Vermicelli",
        "Serving": "2 oz. (56 g)",
        "Calories": 206
    },
    {
        "Food": "Whole Grain Noodles",
        "Serving": "2 oz. (56 g)",
        "Calories": 194
    },
    {
        "Food": "Whole Grain Spaghetti",
        "Serving": "2 oz. (56 g)",
        "Calories": 197
    },
    {
        "Food": "Ziti",
        "Serving": "2 oz. (56 g)",
        "Calories": 197
    },
    {
        "Food": "BBQ Chicken Pizza",
        "Serving": "1 slice (132 g)",
        "Calories": 309
    },
    {
        "Food": "BBQ Pizza",
        "Serving": "1 slice (140 g)",
        "Calories": 314
    },
    {
        "Food": "Beef Pizza",
        "Serving": "1 slice (235 g)",
        "Calories": 714
    },
    {
        "Food": "Bianca Pizza",
        "Serving": "1 slice (187 g)",
        "Calories": 460
    },
    {
        "Food": "Buffalo Chicken Pizza",
        "Serving": "1 slice (238 g)",
        "Calories": 600
    },
    {
        "Food": "Calabrese Pizza",
        "Serving": "1 slice (108 g)",
        "Calories": 254
    },
    {
        "Food": "Calzone",
        "Serving": "1.0zone (364 g)",
        "Calories": 841
    },
    {
        "Food": "Capricciosa Pizza",
        "Serving": "1 slice (74 g)",
        "Calories": 192
    },
    {
        "Food": "Cheese Pizza",
        "Serving": "1 slice (63 g)",
        "Calories": 168
    },
    {
        "Food": "Chicken Pizza",
        "Serving": "1 slice (132 g)",
        "Calories": 309
    },
    {
        "Food": "Deep Dish Pizza",
        "Serving": "1 slice (118 g)",
        "Calories": 313
    },
    {
        "Food": "Dominos Philly Cheese Steak Pizza",
        "Serving": "1 slice (159 g)",
        "Calories": 356
    },
    {
        "Food": "Four Cheese Pizza",
        "Serving": "1 slice (113 g)",
        "Calories": 250
    },
    {
        "Food": "Goat Cheese Pizza",
        "Serving": "1 slice (63 g)",
        "Calories": 138
    },
    {
        "Food": "Grilled Pizza",
        "Serving": "1 slice (124 g)",
        "Calories": 280
    },
    {
        "Food": "Hawaiian Pizza",
        "Serving": "1 slice (134 g)",
        "Calories": 154
    },
    {
        "Food": "Margherita Pizza",
        "Serving": "1 slice (63 g)",
        "Calories": 173
    },
    {
        "Food": "Mozzarella Pizza",
        "Serving": "1 slice (62 g)",
        "Calories": 154
    },
    {
        "Food": "Mushroom Pizza",
        "Serving": "1 slice (288 g)",
        "Calories": 611
    },
    {
        "Food": "Napoli Pizza",
        "Serving": "1 slice (123 g)",
        "Calories": 248
    },
    {
        "Food": "New York Style Pizza",
        "Serving": "1 slice (103 g)",
        "Calories": 174
    },
    {
        "Food": "Pepperoni Pizza",
        "Serving": "1 slice (71 g)",
        "Calories": 181
    },
    {
        "Food": "Pizza Dough",
        "Serving": "2 oz. (57 g)",
        "Calories": 130
    },
    {
        "Food": "Pizza Hut Stuffed Crust Pizza",
        "Serving": "1 slice (149 g)",
        "Calories": 380
    },
    {
        "Food": "Pizza Hut Supreme Pizza",
        "Serving": "1 slice (123 g)",
        "Calories": 305
    },
    {
        "Food": "Pizza Rolls",
        "Serving": "1 roll (14 g)",
        "Calories": 35
    },
    {
        "Food": "Quattro Formaggi Pizza",
        "Serving": "1 slice (133 g)",
        "Calories": 330
    },
    {
        "Food": "Red Pepper Pizza",
        "Serving": "1 slice (86 g)",
        "Calories": 165
    },
    {
        "Food": "Salami Pizza",
        "Serving": "1 slice (71 g)",
        "Calories": 181
    },
    {
        "Food": "Sausage Pizza",
        "Serving": "1 slice (72 g)",
        "Calories": 177
    },
    {
        "Food": "Seafood Pizza",
        "Serving": "1 slice (76 g)",
        "Calories": 186
    },
    {
        "Food": "Shrimp Pizza",
        "Serving": "1 slice (91 g)",
        "Calories": 190
    },
    {
        "Food": "Sicilian Pizza",
        "Serving": "1 slice (133 g)",
        "Calories": 321
    },
    {
        "Food": "Spinach Feta Pizza",
        "Serving": "1 slice (62 g)",
        "Calories": 150
    },
    {
        "Food": "Spinach Pizza",
        "Serving": "1 slice (62 g)",
        "Calories": 145
    },
    {
        "Food": "Stuffed Crust Pizza",
        "Serving": "1 slice (149 g)",
        "Calories": 380
    },
    {
        "Food": "Tarte Flambée",
        "Serving": "1 square (56 g)",
        "Calories": 142
    },
    {
        "Food": "Thin Crust Pizza",
        "Serving": "1 slice (60 g)",
        "Calories": 157
    },
    {
        "Food": "Tuna Pizza",
        "Serving": "1 slice (125 g)",
        "Calories": 318
    },
    {
        "Food": "Vegetable Pizza",
        "Serving": "1 slice (156 g)",
        "Calories": 399
    },
    {
        "Food": "Vegetarian Pizza",
        "Serving": "1 slice (156 g)",
        "Calories": 399
    },
    {
        "Food": "Veggie Pizza",
        "Serving": "1 slice (114 g)",
        "Calories": 263
    },
    {
        "Food": "White Pizza",
        "Serving": "1 slice (232 g)",
        "Calories": 571
    },
    {
        "Food": "Acerola",
        "Serving": "1 acerola (4.8 g)",
        "Calories": 1
    },
    {
        "Food": "Asian Pear",
        "Serving": "1 pear (178 g)",
        "Calories": 75
    },
    {
        "Food": "Avocado",
        "Serving": "1 avocado (200 g)",
        "Calories": 320
    },
    {
        "Food": "Banana",
        "Serving": "1 banana (125 g)",
        "Calories": 111
    },
    {
        "Food": "Breadfruit",
        "Serving": "1/4 breadfruit (96 g)",
        "Calories": 99
    },
    {
        "Food": "Cantaloupe Melon",
        "Serving": "1 wedge (69 g)",
        "Calories": 23
    },
    {
        "Food": "Casaba Melon",
        "Serving": "1 wedge (125 g)",
        "Calories": 35
    },
    {
        "Food": "Cherimoya",
        "Serving": "1 cherimoya (235 g)",
        "Calories": 176
    },
    {
        "Food": "Dragon Fruit",
        "Serving": "1 dragonfruit (200 g)",
        "Calories": 120
    },
    {
        "Food": "Durian",
        "Serving": "1 durian (602 g)",
        "Calories": 885
    },
    {
        "Food": "Feijoa",
        "Serving": "1 feijoa (42 g)",
        "Calories": 23
    },
    {
        "Food": "Galia Melon",
        "Serving": "1 melon (130 g)",
        "Calories": 30
    },
    {
        "Food": "Grapefruit",
        "Serving": "1/2 grapefruit (123 g)",
        "Calories": 52
    },
    {
        "Food": "Guava",
        "Serving": "1 guava (55 g)",
        "Calories": 37
    },
    {
        "Food": "Honeydew",
        "Serving": "1 wedge (125 g)",
        "Calories": 45
    },
    {
        "Food": "Jackfruit",
        "Serving": "1 cup (151 g)",
        "Calories": 143
    },
    {
        "Food": "Kiwi",
        "Serving": "1 kiwi (183 g)",
        "Calories": 112
    },
    {
        "Food": "Kumquat",
        "Serving": "1 kumquat (19 g)",
        "Calories": 13
    },
    {
        "Food": "Lychee",
        "Serving": "1 lychee (10 g)",
        "Calories": 7
    },
    {
        "Food": "Mandarin Oranges",
        "Serving": "1 madarin orange (90 g)",
        "Calories": 48
    },
    {
        "Food": "Mango",
        "Serving": "1 mango (336 g)",
        "Calories": 202
    },
    {
        "Food": "Mangosteen",
        "Serving": "1 serving (80 g)",
        "Calories": 58
    },
    {
        "Food": "Maracuya",
        "Serving": "1 maracuya (18 g)",
        "Calories": 17
    },
    {
        "Food": "Maraschino Cherries",
        "Serving": "1 cherry (5 g)",
        "Calories": 8
    },
    {
        "Food": "Muskmelon",
        "Serving": "1 wedge (69 g)",
        "Calories": 23
    },
    {
        "Food": "Noni",
        "Serving": "1 oz. (28.4 g)",
        "Calories": 15
    },
    {
        "Food": "Passion Fruit",
        "Serving": "1 passion fruit (18 g)",
        "Calories": 17
    },
    {
        "Food": "Pineapple",
        "Serving": "1 pineapple (905 g)",
        "Calories": 453
    },
    {
        "Food": "Pink Grapefruit",
        "Serving": "1/2 grapefruit (123 g)",
        "Calories": 52
    },
    {
        "Food": "Plantain",
        "Serving": "1 plantain (179 g)",
        "Calories": 218
    },
    {
        "Food": "Pomegranate",
        "Serving": "1 pomegranate (282 g)",
        "Calories": 234
    },
    {
        "Food": "Pomelo",
        "Serving": "1 pomelo (609 g)",
        "Calories": 231
    },
    {
        "Food": "Prickly Pear",
        "Serving": "1 pad, peeled (19 g)",
        "Calories": 8
    },
    {
        "Food": "Rambutan",
        "Serving": "1 rambutan (9 g)",
        "Calories": 7
    },
    {
        "Food": "Sapodilla",
        "Serving": "1 sapodilla (170 g)",
        "Calories": 141
    },
    {
        "Food": "Soursop Fruit",
        "Serving": "1 soursop fruit (625 g)",
        "Calories": 413
    },
    {
        "Food": "Star Fruit",
        "Serving": "1 starfruit (91 g)",
        "Calories": 28
    },
    {
        "Food": "Tamarind",
        "Serving": "1 tamarind (2 g)",
        "Calories": 5
    },
    {
        "Food": "Watermelon",
        "Serving": "1 wedge (286 g)",
        "Calories": 86
    },
    {
        "Food": "Arby’s Grand Turkey Club",
        "Serving": "1 sandwich (233 g)",
        "Calories": 489
    },
    {
        "Food": "Arby’s Reuben",
        "Serving": "1 sandwich (308 g)",
        "Calories": 641
    },
    {
        "Food": "Arby’s Roast Beef Classic",
        "Serving": "1 burger (154 g)",
        "Calories": 360
    },
    {
        "Food": "Arby’s Roast Beef Max",
        "Serving": "1 burger (154 g)",
        "Calories": 360
    },
    {
        "Food": "BBQ Rib",
        "Serving": "1 rib (67 g)",
        "Calories": 142
    },
    {
        "Food": "Bean Burrito",
        "Serving": "1 burrito (190 g)",
        "Calories": 380
    },
    {
        "Food": "Big N’ Tasty",
        "Serving": "1 sandwich (232 g)",
        "Calories": 517
    },
    {
        "Food": "Bratwurst",
        "Serving": "1 piece (85 g)",
        "Calories": 283
    },
    {
        "Food": "Burger King Angry Whopper",
        "Serving": "1 burger (290 g)",
        "Calories": 740
    },
    {
        "Food": "Burger King Double Whopper",
        "Serving": "1 burger (374 g)",
        "Calories": 894
    },
    {
        "Food": "Burger King Double Whopper with Cheese",
        "Serving": "1 sandwich (399 g)",
        "Calories": 994
    },
    {
        "Food": "Burger King Original Chicken Sandwich",
        "Serving": "1 sandwich (219 g)",
        "Calories": 659
    },
    {
        "Food": "Burger King Premium Alaskan Fish Sandwich",
        "Serving": "1 burger (228 g)",
        "Calories": 591
    },
    {
        "Food": "Burger King Triple Whopper",
        "Serving": "1 burger (547 g)",
        "Calories": 1471
    },
    {
        "Food": "Burger King Whopper",
        "Serving": "1 burger (291 g)",
        "Calories": 672
    },
    {
        "Food": "Burger King Whopper Jr.",
        "Serving": "1 burger (148 g)",
        "Calories": 346
    },
    {
        "Food": "Burger King Whopper with Cheese",
        "Serving": "1 burger (315 g)",
        "Calories": 759
    },
    {
        "Food": "Cheeseburger",
        "Serving": "1 burger (156 g)",
        "Calories": 410
    },
    {
        "Food": "Chicken Breast",
        "Serving": "1 piece (71 g)",
        "Calories": 116
    },
    {
        "Food": "Chicken Fajita",
        "Serving": "1 sandwich (222 g)",
        "Calories": 326
    },
    {
        "Food": "Chicken McNuggets",
        "Serving": "1 nugget (16 g)",
        "Calories": 48
    },
    {
        "Food": "Chicken Nuggets",
        "Serving": "1 piece (20 g)",
        "Calories": 59
    },
    {
        "Food": "Chicken Pizziola",
        "Serving": "1 sandwich (320 g)",
        "Calories": 451
    },
    {
        "Food": "Chicken Sandwich",
        "Serving": "1 sandwich (170 g)",
        "Calories": 410
    },
    {
        "Food": "Chicken Teriyaki Sandwich",
        "Serving": "1 sandwich (266 g)",
        "Calories": 367
    },
    {
        "Food": "Chicken Wings",
        "Serving": "1 piece (29 g)",
        "Calories": 94
    },
    {
        "Food": "Chop Suey",
        "Serving": "1 serving (340 g)",
        "Calories": 585
    },
    {
        "Food": "Curly Fries",
        "Serving": "1 serving (128 g)",
        "Calories": 398
    },
    {
        "Food": "Double Cheeseburger",
        "Serving": "1 burger (155 g)",
        "Calories": 414
    },
    {
        "Food": "Egg Roll",
        "Serving": "1 roll (80 g)",
        "Calories": 200
    },
    {
        "Food": "Falafel",
        "Serving": "1 patty (17 g)",
        "Calories": 57
    },
    {
        "Food": "Filet-o-Fish",
        "Serving": "1 sandwich (142 g)",
        "Calories": 400
    },
    {
        "Food": "Fish and Chips",
        "Serving": "1 serving (300 g)",
        "Calories": 585
    },
    {
        "Food": "Fish Sandwich",
        "Serving": "1 sandwich (158 g)",
        "Calories": 431
    },
    {
        "Food": "French Fries",
        "Serving": "1 serving (71 g)",
        "Calories": 222
    },
    {
        "Food": "Grilled Chicken Salad",
        "Serving": "1 salad (305 g)",
        "Calories": 268
    },
    {
        "Food": "Ham Sandwich",
        "Serving": "1 sandwich (146 g)",
        "Calories": 352
    },
    {
        "Food": "Hamburger",
        "Serving": "1 sandwich (110 g)",
        "Calories": 279
    },
    {
        "Food": "Hot Dog",
        "Serving": "1 hot dog (116 g)",
        "Calories": 312
    },
    {
        "Food": "Italian BMT",
        "Serving": "1 sandwich (224 g)",
        "Calories": 410
    },
    {
        "Food": "Lasagna",
        "Serving": "1 piece (130 g)",
        "Calories": 172
    },
    {
        "Food": "McDonald’s Big Mac",
        "Serving": "1 burger (219 g)",
        "Calories": 561
    },
    {
        "Food": "McDonald’s Cheeseburger",
        "Serving": "1 sandwich (114 g)",
        "Calories": 300
    },
    {
        "Food": "McDonald’s Chicken Nuggets",
        "Serving": "1 nugget (16 g)",
        "Calories": 48
    },
    {
        "Food": "McDonald’s Double Cheeseburger",
        "Serving": "1 burger (155 g)",
        "Calories": 437
    },
    {
        "Food": "McDonald’s Filet-o-Fish",
        "Serving": "1 sandwich (142 g)",
        "Calories": 391
    },
    {
        "Food": "McDonald’s McChicken",
        "Serving": "1 burger (143 g)",
        "Calories": 359
    },
    {
        "Food": "McDonald’s McDouble",
        "Serving": "1 burger (160 g)",
        "Calories": 403
    },
    {
        "Food": "McDonald’s McMuffi Egg",
        "Serving": "1 burger (129 g)",
        "Calories": 290
    },
    {
        "Food": "McDonald’s McRib",
        "Serving": "1 sandwich (170 g)",
        "Calories": 451
    },
    {
        "Food": "McDonald’s Mighty Wings",
        "Serving": "1 wing (31 g)",
        "Calories": 95
    },
    {
        "Food": "McRib",
        "Serving": "1 sandwich (170 g)",
        "Calories": 451
    },
    {
        "Food": "Meatball Sandwich",
        "Serving": "1 sandwich (299 g)",
        "Calories": 481
    },
    {
        "Food": "Nachos with Cheese",
        "Serving": "10 nachos (188 g)",
        "Calories": 575
    },
    {
        "Food": "Onion Rings",
        "Serving": "1 ring (6.5 g)",
        "Calories": 25
    },
    {
        "Food": "Poutine",
        "Serving": "1 serving (225 g)",
        "Calories": 511
    },
    {
        "Food": "Smoked Salmon",
        "Serving": "1 oz. (28.35 g)",
        "Calories": 44
    },
    {
        "Food": "Spicy Italian",
        "Serving": "1 sandwich (219 g)",
        "Calories": 480
    },
    {
        "Food": "Subway Club Sandwich",
        "Serving": "1 sandwich (238 g)",
        "Calories": 312
    },
    {
        "Food": "Tortilla Wrap",
        "Serving": "1 wrap (63 g)",
        "Calories": 171
    },
    {
        "Food": "Tuna",
        "Serving": "1 oz. (28.35 g)",
        "Calories": 24
    },
    {
        "Food": "Turkey",
        "Serving": "1 oz. (28.35 g)",
        "Calories": 29
    },
    {
        "Food": "Veggie Burger",
        "Serving": "1 burger (215 g)",
        "Calories": 389
    },
    {
        "Food": "Veggie Delight",
        "Serving": "1 sandwich (166 g)",
        "Calories": 229
    },
    {
        "Food": "Veggie Patty",
        "Serving": "1 sandwich (247 g)",
        "Calories": 963
    },
    {
        "Food": "Wendy’s Baconator",
        "Serving": "1 burger (276 g)",
        "Calories": 839
    },
    {
        "Food": "Wendy’s Jr. Bacon Cheeseburger",
        "Serving": "1 burger (161 g)",
        "Calories": 420
    },
    {
        "Food": "Wendy’s Jr. Cheeseburger",
        "Serving": "1 burger (129 g)",
        "Calories": 290
    },
    {
        "Food": "Wendy’s Son of Baconator",
        "Serving": "1 burger (218 g)",
        "Calories": 700
    },
    {
        "Food": "Whopper",
        "Serving": "1 burger (291 g)",
        "Calories": 672
    },
    {
        "Food": "Zinger",
        "Serving": "1 sandwich (202 g)",
        "Calories": 517
    },
    {
        "Food": "Zinger Burger",
        "Serving": "1 sandwich (202 g)",
        "Calories": 517
    }
]

function getFoodData(){
    return foodData;
}

export {getFoodData}