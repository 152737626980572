import { Doughnut } from "react-chartjs-2";
import React from "react";

function makeData(d) {
  const data = {
    labels: ["Breakfast", "Lunch", "Dinner"],
    datasets: [
      {
        data: d,
        backgroundColor: ["green", "red", "yellow"],
        hoverBackgroundColor: ["green", "red", "yellow"],
      },
    ],
  };
  return data;
}

function DoughnutChart(props) {
  return (
    <div>
      <div style={{ height: 300, width: 400 }}>
        <Doughnut data={makeData(props.data)} />
      </div>
    </div>
  );
}

export default DoughnutChart;
