import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function Navigation(props) {
  const auth = props.auth;
  const page = props.page;
  const history = useHistory();

  const tabNameToIndex = {
    0: "select",
    1: "nutritionpage",
    2: "historypage",

  };

  const indexToTabName = {
    select: 0,
    nutritionpage:1,
    historypage: 2,
  };

  const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 3) {
      auth.signOut();
    }
    history.push(tabNameToIndex[newValue]);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={selectedTab} onChange={handleChange}>
          <Tab label="Workout" />
          <Tab label="Nutrition" />
          <Tab label="History" />
          <Tab label="Sign out" />
        </Tabs>
      </AppBar>
    </>
  );
}

export default Navigation;
