import { useEffect, useState } from "react";
import {getModelURL} from "../utilities/Helper"
import to from "await-to-js";

export default function LoadModel(key) {
  const [model, setModel] = useState(null);

  useEffect(() => {

    const tmPose = window.tmPose;
    const URL = getModelURL(key);
    const modelURL = URL + "model.json";
    const metadataURL = URL + "metadata.json";
    console.log('exercise elected: ' + key)
    async function loadModel() {
      const [err, net] = await to(tmPose.load(modelURL, metadataURL));

      if (err) {
        setModel(err);
        console.log(err);
        return;
      }
      setModel(net);
      if (net){
        console.log("model loaded.");
      }
    }

    loadModel();
  }, [key]);

  return model;
}
